import React from 'react';
import PropTypes from 'prop-types';

const Temp5 = ({ name, nickname, description1, description2, photos }) => {
  // Ensure description2 and photos are arrays
  const activities = Array.isArray(description2) ? description2 : [];
  const photoUrls = Array.isArray(photos) ? photos : [];

  return (
    <div className="fathers-day-template" style={styles.template}>
      <h1 style={styles.heading}>Happy Father's Day, {name}!</h1>
      <p style={styles.subheading}>With love, {nickname}</p>
      <p style={styles.message}>{description1}</p>
      <p style={styles.message}>{description2}</p>

      
      {activities.length > 0 && (
        <ul style={styles.activitiesList}>
          {activities.map((activity, index) => (
            <li key={index} style={styles.activityItem}>{activity}</li>
          ))}
        </ul>
      )}

      {photoUrls.length > 0 && (
        <div className="photos" style={styles.photos}>
          {photoUrls.map((photo, index) => (
            <img
              key={index}
              src={photo} // Assuming `photo` is a valid URL
              alt={`Uploaded ${index + 1}`}
              style={styles.photo}
            />
          ))}
        </div>
      )}
    </div>
  );
};

Temp5.propTypes = {
  name: PropTypes.string.isRequired,
  nickname: PropTypes.string.isRequired,
  description1: PropTypes.string.isRequired,
  description2: PropTypes.arrayOf(PropTypes.string), // Ensure description2 is an array of strings
  photos: PropTypes.arrayOf(PropTypes.string), // Ensure photos is an array of strings (URLs)
};

const styles = {
  template: {
    textAlign: 'center',
    padding: '20px',
    backgroundColor: '#f9f9f9',
    border: '1px solid #ddd',
    borderRadius: '10px',
  },
  heading: {
    color: '#333',
    fontSize: '2em',
  },
  subheading: {
    color: '#666',
    fontSize: '1.2em',
  },
  message: {
    color: '#666',
    fontSize: '1.2em',
  },
  activitiesList: {
    listStyleType: 'none',
    padding: 0,
  },
  activityItem: {
    color: '#444',
    fontSize: '1em',
  },
  photos: {
    marginTop: '20px',
  },
  photo: {
    width: '100px',
    height: '100px',
    objectFit: 'cover',
    margin: '5px',
    borderRadius: '10px',
  },
};

export default Temp5;
