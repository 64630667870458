// Function to import all images from a given context
const importAll = (r) => {
  let images = {};
  // Iterate over each key (file path) in the context
  r.keys().map((item, index) => {
    // Add each image to the images object with the file name as the key
    images[item.replace("./", "")] = r(item);
  });
  return images;
};

// Dynamically import all images from the 'birthdayImg' directory
const birthdayImages = importAll(
  require.context("./birthdayImg", false, /\.(png|jpe?g|svg)$/)
);
// Dynamically import all images from the 'fathersDayImg' directory
const fathersDayImages = importAll(
  require.context("./fathersDayImg", false, /\.(png|jpe?g|svg)$/)
);
// Dynamically import all images from the 'anniversaryImg' directory
const anniversaryImages = importAll(
  require.context("./anniversaryImg", false, /\.(png|jpe?g|svg)$/)
);
const raskhyaImages = importAll(
  require.context("./rakshyabandanImg", false, /\.(png|jpe?g|svg)$/)
);

// Create an object to categorize the imported images
const imagePaths = {
  Birthday: birthdayImages,
  FathersDay: fathersDayImages,
  Anniversary: anniversaryImages,
  Rakshya: raskhyaImages,
};

// Export the imagePaths object as the default export
export default imagePaths;
