// FindTemplate.js
import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import TemplateSelector from "./TempComponent/TemplateSelector";
import "./TempComponent/Templatestyle.css";
import BirthdayTemplateInfo from "./TempComponent/Birthday/TemplateInfo";
import FatherTemplateInfo from "./TempComponent/FathersDay/TemplateInfo";
import AniversaryTemplateInfo from "./TempComponent/Anniversary/TemplateInfo";
import step1 from "../imag/howto/step1.png";
import step2 from "../imag/howto/step2.png";
import step3 from "../imag/howto/step3.png";
import step4 from "../imag/howto/step4.png";
import step5 from "../imag/howto/step5.png";
import step6 from "../imag/howto/step6.png";

const Howto = () => {
  return (
    <>
      <Header />
      <div className="find-template-container">
        <div className="mt-5">
          <h1 data-aos="fade-in" data-aos-offset="150">
            How to use Snapgreet Template
          </h1>
          <div className="howtoimage px-4 py-3 text-dark ">
            <p className="py-4">
              <h3>Step 1</h3>
              <img src={step1} className="img-fluid" alt="" />
            </p>
            <p className="py-4 text-start">
              <h4>Guideline</h4>
              <li>Name: Type name to whom you want to wish </li>
              <li>Nickname: Write a nickname to the person </li>{" "}
              <li>
                Description(beginning): Write short lines to express your
                feelings
              </li>
              <li>
                {" "}
                Description(conclusion): Write short conclusion lines to express
                your feelings
              </li>
              <li>Choose Pictures: Pick photos of the person as mentioned</li>
            </p>
            <p className="py-4">
              <h3>Step 2</h3>

              <img src={step2} className="img-fluid" alt="" />
            </p>
            <p className="py-4">
              <h3>Step 3</h3>

              <img src={step3} className="img-fluid" alt="" />
            </p>
            <p className="py-4 text-start">
              <h4>Payment Method:</h4>
              <li>
                Screenshot or scan the QR code given and complete the payment
                with respective price{" "}
              </li>
              <li>
                Make sure to copy the code given, and paste it in the remarks
                section while payment process
              </li>{" "}
            </p>
            <p className="py-4">
              <h3>Step 4</h3>

              <img src={step4} className="img-fluid" alt="" />
            </p>
            <p className="py-4">
              <h3>Step 5</h3>

              <img src={step5} className="img-fluid" alt="" />
            </p>{" "}
            <p className="py-4 text-start">
              <h4>Result template:</h4>
              <li>
                Click on the notification section inside the sidebar or
                notification tab on homepage.
              </li>
              <li>
                Click on visit template to view your result and click on copy
                button to share on social medias.
              </li>{" "}
            </p>
            <p className="py-4">
              <h3>Step 6</h3>

              <img src={step6} className="img-fluid" alt="" />
            </p>
          </div>{" "}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Howto;
