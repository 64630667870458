import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { useLocation } from "react-router-dom";
import Hero from "./js/Hero.jsx";
import Everheard from "./js/EverHeard.jsx";
import Choosingus from "./js/ChoosingUs";
import TemplateSelector from "./TempComponent/TemplateSelector.js";
import Popup from "./Popup/Popup.jsx";
import BirthdayTemplateInfo from "./TempComponent/Birthday/TemplateInfo";
import FatherTemplateInfo from "./TempComponent/FathersDay/TemplateInfo";
import AniversaryTemplateInfo from "./TempComponent/Anniversary/TemplateInfo";
import RakshyaBandanTemplateInfo from "./TempComponent/Rakshya_Bandann/TemplateInfo.js";
import "./Style.css";

const limitTemplates = (templateInfo, limit) => {
  // Extract keys and limit the number of templates
  const limitedTemplates = {};
  Object.keys(templateInfo)
    .slice(0, limit)
    .forEach((key) => {
      limitedTemplates[key] = templateInfo[key];
    });
  return limitedTemplates;
};

// Limit number of templates to show from each category
const limit = 2;

const limitedBirthdayTemplates = limitTemplates(BirthdayTemplateInfo, limit);
const limitedFatherTemplates = limitTemplates(FatherTemplateInfo, limit);
const limitedAnniversaryTemplates = limitTemplates(
  AniversaryTemplateInfo,
  limit
);
const limitedRakshyaBandan = limitTemplates(RakshyaBandanTemplateInfo, limit);

// Combine limited templates from all categories
const allLimitedTemplates = {
  ...limitedBirthdayTemplates,
  // ...limitedFatherTemplates,
  ...limitedAnniversaryTemplates,
  ...limitedRakshyaBandan,
};

const HomePage = () => {
  const location = useLocation();
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const shouldShowPopup = sessionStorage.getItem("showPopup");
    if (
      location.state &&
      location.state.showPopup &&
      shouldShowPopup === "true"
    ) {
      setShowPopup(true);
      sessionStorage.removeItem("showPopup");
    }
  }, [location.state]);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  return (
    <>
      <Header />
      <Hero />
      <Everheard></Everheard>
      <Choosingus></Choosingus>

      <div className="mt-3">
        <div className="Featured">
          <h1 className="text-center">Featuressd Templates</h1>
          <div className="container">
            <div className="row justify-content-center flex-wrap">
              <div className="mt-5">
                <TemplateSelector
                  templates={Object.keys(allLimitedTemplates)}
                  templateInfo={allLimitedTemplates}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {showPopup && (
        <Popup
          message="Your request has been submitted. Please wait a few moments for your template to be ready. Go to the notification section to view the template."
          onClose={handleClosePopup}
        />
      )}

      <Footer />
    </>
  );
};

export default HomePage;
