import React from "react";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "../Style.css";
import { Pagination, Navigation } from "swiper/modules";
import imagePaths from "./template-previews/imagePaths"; // Import the image paths

const TemplateSelector = ({ templates = [], templateInfo = {} }) => {
  const navigate = useNavigate();

  const handleSelectTemplate = (type) => {
    navigate(`/templatedetail/${type}`, {
      state: { price: templateInfo[type]?.price || "N/A" },
    });
  };

  // Function to get the image source for a given template type
  const getImageSrc = (type) => {
    // Loop through the categories and their images
    for (const [category, images] of Object.entries(imagePaths)) {
      // Check if any image file name includes the template type
      const imagePath = Object.keys(images).find((img) => img.includes(type));
      if (imagePath) {
        return images[imagePath]; // Return the matching image path
      }
    }
    return "";
  };

  return (
    <div className="template-selector">
      <Swiper
        slidesPerView={1}
        spaceBetween={10}
        centeredSlides={true}
        pagination={{ clickable: true }}
        navigation={true}
        modules={[Pagination, Navigation]}
        className="mySwiper"
        breakpoints={{
          320: { slidesPerView: 1, spaceBetween: 0 },
          480: { slidesPerView: 1, spaceBetween: 0 },
          640: { slidesPerView: 2, spaceBetween: 10 },
          768: { slidesPerView: 4, spaceBetween: 10 },
        }}
      >
        {templates.map((type) => (
          <SwiperSlide key={type}>
            <div className="template-option">
              <div
                className="temp-card"
                data-aos="slide-left"
                data-aos-offset="100"
              >
                <img
                  src={getImageSrc(type)}
                  alt={`${type} preview`}
                  className="template-image"
                />
                <div className="card-text px-3">
                  <div className="title">
                    <p className="text-start">
                      {templateInfo[type]?.title || "Unknown Template"}
                    </p>
                  </div>
                  <div className="d-flex justify-content-between">
                    <p>Rs. {templateInfo[type]?.price || "N/A"}</p>
                    <p
                      className="btn-hero"
                      onClick={() => handleSelectTemplate(type)}
                    >
                      Get now
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default TemplateSelector;
