import React, { useEffect, useState } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import axios from "axios";
import "./css/admin.css";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import AdminMenu from "../../components/Menu/AdminMenu";
import defaultProfilePhoto from "../../imag/user/profile.jpg";
import loadingGif from "../../imag/snapload.gif";

const Request = () => {
  const [pendingWishes, setPendingWishes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [loadingWish, setLoadingWish] = useState({ id: null, action: null });

  const { userId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    fetchPendingWishes(currentPage);
  }, [currentPage]);

  const fetchPendingWishes = async (page) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/api/v1/admin/pending-wishes?page=${page}&limit=${itemsPerPage}`
      );
      setPendingWishes(response.data.pendingWishes);
      setTotalPages(response.data.totalPages);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching pending wishes:", error);
      setLoading(false);
    }
  };

  const approveWish = async (id) => {
    setLoadingWish({ id, action: "approve" });
    try {
      await axios.patch(`${process.env.REACT_APP_API}/api/v1/admin/approve/${id}`);
      fetchPendingWishes(currentPage);
    } catch (error) {
      console.error("Error approving wish:", error);
    } finally {
      setLoadingWish({ id: null, action: null });
    }
  };

  const rejectWish = async (id) => {
    setLoadingWish({ id, action: "reject" });
    try {
      await axios.patch(`${process.env.REACT_APP_API}/api/v1/admin/reject/${id}`);
      fetchPendingWishes(currentPage);
    } catch (error) {
      console.error("Error rejecting wish:", error);
    } finally {
      setLoadingWish({ id: null, action: null });
    }
  };

  const arrayBufferToBase64 = (buffer) => {
    let binary = "";
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  };

  const renderImage = (photo) => {
    if (!photo || !photo.data) return defaultProfilePhoto;
    const base64String = arrayBufferToBase64(photo.data.data);
    return `data:${photo.contentType};base64,${base64String}`;
  };

  return (
    <>
      <Header />
      <div className="container p-3 pt-4">
        <div className="row">
          <div className="col-md-2">{/* <AdminMenu /> */}</div>
          <div className="col-md-9 pt-4">
            <div className="admin-container">
              <h2>Pending Wishes</h2>
              {loading ? (
                <div className="loading-container">
                  <img src={loadingGif} alt="Loading..." className="loading-gif" />
                </div>
              ) : (
                pendingWishes.map((wish) => (
                  <div className="list-group-item notification" key={wish._id}>
                    <div className="content">
                      {wish.postedBy ? (
                        <>
                          <Link to={`/request-info/${wish.postedBy._id}`} className="link">
                            <img
                              src={renderImage(wish.postedBy.photo)}
                              alt="User Photo"
                              className="user-photo"
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = defaultProfilePhoto;
                              }}
                            />
                          </Link>
                          <div className="user-details">
                            <div className="header">
                              {wish.postedBy.name} : {wish.postedBy.phone}
                            </div>
                          </div>
                        </>
                      ) : (
                        <div className="user-details">
                          <div className="header">User details not available</div>
                        </div>
                      )}
                      <div className="header">Code: {wish.randomChars}</div>
                      <div className="meta">
                        {wish.templateType} : {wish.name}
                      </div>
                    </div>
                    <div className="d-flex flex-column">
                      <button
                        className="approve-button my-2"
                        onClick={() => approveWish(wish._id)}
                        disabled={loadingWish.id === wish._id && loadingWish.action === "approve"}
                      >
                        {loadingWish.id === wish._id && loadingWish.action === "approve"
                          ? "Approving..."
                          : "Approve"}
                      </button>
                      <button
                        className="reject-button px-3"
                        onClick={() => rejectWish(wish._id)}
                        disabled={loadingWish.id === wish._id && loadingWish.action === "reject"}
                      >
                        {loadingWish.id === wish._id && loadingWish.action === "reject"
                          ? "Rejecting..."
                          : "Reject"}
                      </button>
                    </div>
                  </div>
                ))
              )}
              <nav aria-label="Page navigation example">
                <ul className="pagination justify-content-center">
                  <li className={`page-item ${currentPage === 1 && "disabled"}`}>
                    <button
                      className="page-link"
                      onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                      disabled={currentPage === 1}
                    >
                      Previous
                    </button>
                  </li>
                  {Array.from({ length: totalPages }, (_, index) => (
                    <li
                      className={`page-item ${currentPage === index + 1 && "active"}`}
                      key={index}
                    >
                      <button
                        className="page-link"
                        onClick={() => setCurrentPage(index + 1)}
                      >
                        {index + 1}
                      </button>
                    </li>
                  ))}
                  <li className={`page-item ${currentPage === totalPages && "disabled"}`}>
                    <button
                      className="page-link"
                      onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
                      disabled={currentPage === totalPages}
                    >
                      Next
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Request;
