import React, { useState, useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useAuth } from "../../context/auth";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import "./Templatestyle.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { FreeMode, Navigation, Thumbs } from "swiper/modules";
import { GrShare } from "react-icons/gr";

const TemplateDetail = () => {
  const { templateType } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { price, userTemp } = location.state || { price: "N/A", userTemp: {} };
  const [auth] = useAuth();
  const [templateDescription, setTemplateDescription] = useState("");
  const [templateTitle, setTemplateTitle] = useState("");
  const [images, setImages] = useState([]); // State to hold an array of images
  const [thumbsSwiper, setThumbsSwiper] = useState(null); // State for the thumbnails Swiper instance
  const [previewUrl, setPreviewUrl] = useState(""); // State for the preview URL

  const getTemplateInfoImport = async (templateType) => {
    try {
      const folders = [
        "Birthday",
        "Anniversary",
        "FathersDay",
        "Rakshya_Bandann",
      ];
      for (const folder of folders) {
        try {
          const module = await import(`./${folder}/TemplateInfo.js`);
          if (module.default[templateType]) {
            return module.default[templateType];
          }
        } catch (error) {
          console.error(`Error loading template from ${folder}:`, error);
        }
      }
    } catch (error) {
      console.error("Error loading TemplateInfo:", error);
    }
    return null;
  };

  const fetchTemplateTitle = async (templateType, setTemplateTitle) => {
    try {
      const templateInfo = await getTemplateInfoImport(templateType);
      if (templateInfo) {
        setTemplateTitle(templateInfo.title);
      } else {
        setTemplateTitle("Title not available.");
      }
    } catch (error) {
      console.error("Error loading template title:", error);
      setTemplateTitle("Title not available.");
    }
  };

  useEffect(() => {
    const loadTemplateDescription = async () => {
      try {
        let module;
        let importPath;

        const detailFolders = [
          "BirthdayDetail",
          "FathersDayDetail",
          "AnniversaryDetail",
          "Rakshya_BandannDetail",
        ];
        const componentFolders = [
          "Birthday",
          "FathersDay",
          "Anniversary",
          "Rakshya_Bandann",
        ];

        const handleImageImport = (importedModule) => {
          setTemplateDescription(importedModule.default.description);
          setImages(importedModule.default.images || []);
          setPreviewUrl(importedModule.default.url || ""); // Set the preview URL
        };

        let errorLogged = false;

        for (const folder of detailFolders) {
          importPath = `./TemplateDetails/${folder}/${templateType}.jsx`;
          try {
            module = await import(`${importPath}`);
            handleImageImport(module);
            return;
          } catch (error) {
            if (!errorLogged) {
              console.error(`Error importing from ${importPath}:`, error);
              errorLogged = true;
            }
          }
        }

        for (const folder of componentFolders) {
          importPath = `./TemplateComponents/${folder}/${templateType}.jsx`;
          try {
            module = await import(`${importPath}`);
            handleImageImport(module);
            return;
          } catch (error) {
            if (!errorLogged) {
              console.error(`Error importing from ${importPath}:`, error);
              errorLogged = true;
            }
          }
        }

        setTemplateDescription("Description not available.");
      } catch (error) {
        console.error("Error loading template description:", error);
        setTemplateDescription("Description not available.");
      }
    };

    loadTemplateDescription();
    fetchTemplateTitle(templateType, setTemplateTitle);
  }, [templateType]);

  const handleUseTemplate = () => {
    if (!auth?.user) {
      navigate("/login", { state: { from: location } });
    } else {
      navigate(`/templateform/${templateType}`, { state: { price, userTemp } });
    }
  };

  const handlePreview = () => {
    if (previewUrl) {
      window.open(previewUrl, "_blank");
    }
  };

  const handleHowTo = () => {
    navigate(`/howto`);
  };

  const handleError = (event) => {
    event.target.src = `/template-previews/${templateType}.png`;
  };

  return (
    <>
      <Header />
      <div className="container">
        <div className="row template-detail align-items-center">
          <div className="col-lg-5 px-4 mx-2">
            <div className="template-image-container">
              <Swiper
                style={{
                  "--swiper-navigation-color": "#fff",
                  "--swiper-pagination-color": "#fff",
                }}
                spaceBetween={10}
                navigation={true}
                thumbs={{ swiper: thumbsSwiper }}
                modules={[FreeMode, Navigation, Thumbs]}
                className="mySwiper2 mb-3"
              >
                {images.map((image, index) => (
                  <SwiperSlide key={index}>
                    <img
                      src={image}
                      alt={`Slide ${index}`}
                      onError={handleError}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
              <Swiper
                onSwiper={setThumbsSwiper}
                spaceBetween={10}
                slidesPerView={4}
                freeMode={true}
                watchSlidesProgress={true}
                modules={[FreeMode, Navigation, Thumbs]}
                className="mySwiper"
              >
                {images.map((image, index) => (
                  <SwiperSlide key={index}>
                    <img
                      src={image}
                      alt={`Thumb ${index}`}
                      onError={handleError}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
          <div className="col-lg-5 text-start">
            <h2>{templateTitle}</h2>
            <p className="text-danger">Rs. {price}</p>
            <button
              className="btn-purple text-white px-1 py-1 me-2"
              onClick={handleUseTemplate}
            >
              Use Template
            </button>
            <button
              className="btn-purple text-white px-1 py-1"
              onClick={handleHowTo}
            >
              How To?
            </button>
            <p className="mt-4">{templateDescription}</p>
            {previewUrl && (
              <button
                className="btn-purple text-white px-1 py-2"
                onClick={handlePreview}
              >
                <span className="me-2 py-3">
                  <GrShare />
                </span>
                Preview
              </button>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default TemplateDetail;
