import React, { useState, useEffect } from "react";
import axios from "axios";
import { useAuth } from "../../context/auth";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import "./css/UserTemp.css"; // Import CSS file for custom styles
import imagePaths from "../TempComponent/template-previews/imagePaths"; // Import the image paths
import AniversaryTemplateInfo from "../TempComponent/Anniversary/TemplateInfo";
import BirthdayTemplateInfo from "../TempComponent/Birthday/TemplateInfo";
import FathersDayTemplateInfo from "../TempComponent/FathersDay/TemplateInfo";
import RakshyaBandanTemplateInfo from "../TempComponent/Rakshya_Bandann/TemplateInfo";

const UserTemp = ({ templateInfo = {} }) => {
  const [error, setError] = useState(null);
  const [templates, setTemplates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [auth] = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTemplates = async () => {
      setLoading(true); // Start loading
      setError(null); // Clear previous error

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API}/api/v1/auth/usertemp/${auth.user._id}`
        );
        setTemplates(response.data || []);
      } catch (error) {
        if (error.response && error.response.status === 404) {
          setTemplates([]); // No templates found
        } else {
          setError(error.message); // Other errors
        }
      } finally {
        setLoading(false); // Stop loading
      }
    };

    if (auth.user) {
      fetchTemplates();
    } else {
      setLoading(false); // If no user, stop loading
    }
  }, [auth.user]);

  if (!auth.user) {
    return <p>User is not logged in.</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  const getImageSrc = (templateType) => {
    // Loop through the categories and their images
    for (const [category, images] of Object.entries(imagePaths)) {
      // Check if any image file name includes the template type
      const imagePath = Object.keys(images).find((img) =>
        img.includes(templateType)
      );
      if (imagePath) {
        return images[imagePath]; // Return the matching image path
      }
    }
    return "";
  };

  const getFallbackImageSrc = (templateType) => {
    return `/template-previews/${templateType}.png`;
  };

  const getTemplateInfo = (templateType) => {
    // Example condition to determine category, adjust logic as necessary
    if (templateType in AniversaryTemplateInfo) {
      return AniversaryTemplateInfo[templateType];
    } else if (templateType in BirthdayTemplateInfo) {
      return BirthdayTemplateInfo[templateType];
    } else if (templateType in FathersDayTemplateInfo) {
      return FathersDayTemplateInfo[templateType];
    } else if (templateType in RakshyaBandanTemplateInfo) {
      return RakshyaBandanTemplateInfo[templateType];
    }
    return null;
  };

  const handleUseTemplate = (templateType) => {
    const template = templates.find((t) => t.templateType === templateType);
    const templateDetails = getTemplateInfo(templateType);

    if (template && templateDetails) {
      navigate(`/templatedetail/${templateType}`, {
        state: { price: templateDetails.price || "N/A" },
      });
    }
  };

  return (
    <>
      <Header />
      <div className="container p-3 pt-4">
        <div className="row">
          <div className="col-md-3">{/* <UserMenu /> */}</div>
          <div className="col-md-9 pt-4 ">
            <h1 className="py-3 poppins">All Used Templates</h1>
            <div className="card-container text-center justify-content">
              {loading && <p className="loading">Loading...</p>}
              {!loading && templates.length === 0 && <p>No templates found.</p>}
              {!loading &&
                templates.length > 0 &&
                templates.map((template, index) => (
                  <div key={index} className="card">
                    {template.status !== "rejected" ? (
                      <a
                        href={`http://localhost:3000/${template.templateType}/${template._id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={getImageSrc(template.templateType)}
                          alt={`${template.templateType} preview`}
                          className="card-img-top template-image"
                          onError={(e) => {
                            e.target.onerror = null; // Prevent infinite loop if fallback also fails
                            e.target.src = getFallbackImageSrc(
                              template.templateType
                            );
                          }}
                        />
                      </a>
                    ) : (
                      <img
                        src={getImageSrc(template.templateType)}
                        alt={`${template.templateType} preview`}
                        className="card-img-top template-image"
                        onError={(e) => {
                          e.target.onerror = null; // Prevent infinite loop if fallback also fails
                          e.target.src = getFallbackImageSrc(
                            template.templateType
                          );
                        }}
                      />
                    )}
                    <div className="card-body">
                      <h5 className="card-title">{template.templateType}</h5>
                      <p className="card-text-1">{template.name}</p>
                      <a
                        href={`http://localhost:3000/${template.templateType}/${template._id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <button className="btn btn-primary my-3">
                          Visit Template{" "}
                        </button>
                      </a>{" "}
                      <br></br>
                      {template.status !== "rejected" && (
                        <button
                          onClick={() =>
                            handleUseTemplate(template.templateType)
                          }
                          className="btn btn-primary"
                        >
                          Use Template
                        </button>
                      )}{" "}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default UserTemp;
