// FindTemplate.js
import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import TemplateSelector from "./TempComponent/TemplateSelector";
import "./TempComponent/Templatestyle.css";
import BirthdayTemplateInfo from "./TempComponent/Birthday/TemplateInfo";
import FatherTemplateInfo from "./TempComponent/FathersDay/TemplateInfo";
import AniversaryTemplateInfo from "./TempComponent/Anniversary/TemplateInfo";
import RakshyaBandanTemplateInfo from "./TempComponent/Rakshya_Bandann/TemplateInfo";

const FindTemplate = () => {
  return (
    <>
      <Header />
      <div className="find-template-container">
        <div className="mt-5">
          <h1>Birthday Templates</h1>
          <div className="template-selector-wrapper mb-5">
            <TemplateSelector
              templates={Object.keys(BirthdayTemplateInfo)}
              templateInfo={BirthdayTemplateInfo}
            />
          </div>
          <h1>Digital Love Letter</h1>
          <div className="template-selector-wrapper mb-5">
            <TemplateSelector
              templates={Object.keys(AniversaryTemplateInfo)}
              templateInfo={AniversaryTemplateInfo}
            />
          </div>
          {/* <h1>Fathers Day</h1>
          <div className="template-selector-wrapper mb-5">
            <TemplateSelector
              templates={Object.keys(FatherTemplateInfo)}
              templateInfo={FatherTemplateInfo}
            />
          </div> */}{" "}
          <h1>Rakshya Bandhann</h1>
          <div className="template-selector-wrapper mb-5">
            <TemplateSelector
              templates={Object.keys(RakshyaBandanTemplateInfo)}
              templateInfo={RakshyaBandanTemplateInfo}
            />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default FindTemplate;
