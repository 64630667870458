import React from "react";
import { NavLink, Link } from "react-router-dom";
import { useAuth } from "../context/auth";
import toast from "react-hot-toast";
import "./Header.css";
import logo from "../imag/fullsnap.png";
import defaultProfilePhoto from "../imag/user/profile.jpg";
import { LuHome } from "react-icons/lu";
import { MdOutlineNotificationsActive } from "react-icons/md";
import Ham from "../imag/Vector.png";
import foryou from "../imag/star.png";
import notify from "../imag/notification-bell.png";
import browse from "../imag/browser.png";
import click from "../imag/click.png";

import { GoBrowser } from "react-icons/go";
import { IoMdArrowDropright } from "react-icons/io";
import { MdNotes } from "react-icons/md";

const Header = () => {
  const [auth, setAuth] = useAuth();

  const handleLogout = () => {
    setAuth({
      ...auth,
      user: null,
      token: "",
    });
    localStorage.removeItem("auth");
    toast.success("Logout Success");
  };

  const userPhotoUrl = auth?.user
    ? `${process.env.REACT_APP_API}/api/v1/auth/user-photo/${auth.user._id}`
    : defaultProfilePhoto;

  return (
    <>
      <nav className="navbar navbar-expand-lg">
        <div className="container nav-container px-4 py-2">
          <Link to="/" className="navbar-brand">
            <div className="d-flex">
              <div className="logoimg">
                <img src={logo} className="img-fluid" alt="Logo" />
              </div>
              {/* <span className="logoname">SnapGreet</span> */}
            </div>
          </Link>

          <div className="justify-content-end d-flex align-items-center rightnav">
            <a
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasScrolling"
              aria-controls="offcanvasScrolling"
            >
              <img src={Ham} className="Ham" alt="Menu" />
            </a>
          </div>
        </div>
      </nav>

      {/* OFF CANVAS */}
      <div
        class="offcanvas offcanvas-start"
        data-bs-scroll="true"
        // data-bs-backdrop="false"
        tabindex="-1"
        id="offcanvasScrolling"
        aria-labelledby="offcanvasScrollingLabel"
      >
        <div className="offcanvas-header box">
          <h5 class="offcanvas-title" id="offcanvasScrollingLabel">
            Menu
          </h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body">
          <div className="mt-3">
            <div className="mt-1 d-flex justify-content-between canvas-top">
              {auth.user ? (
                <NavLink
                  to={`/dashboard/${
                    auth?.user?.role === 1 ? "admin" : "user/userdb"
                  }`}
                  role="button"
                >
                  <div
                    className="d-flex justify-content-between"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  >
                    <div className="d-flex user-profile">
                      <span className="user-photo">
                        <img
                          src={userPhotoUrl}
                          alt="User Photo"
                          className="img-fluid profile-icon"
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = defaultProfilePhoto;
                          }}
                        />
                      </span>
                      <div className="py-2 mx-3">
                        <h5 className="text-dark">{auth?.user?.name}</h5>
                      </div>
                    </div>
                    <div className="py-2 mx-1 d-flex">
                      <span className="text-dark">
                        <IoMdArrowDropright />
                      </span>
                    </div>
                  </div>
                </NavLink>
              ) : (
                <li className="nav-item mx-2 loginbtn">
                  <Link to="/login" className="dark-font nav-link px-2">
                    Login
                  </Link>
                </li>
              )}
            </div>
            <div className="mt-1">
              <NavLink to="/" className="mx-1 text-dark">
                <div data-bs-dismiss="offcanvas" aria-label="Close">
                  <div className="nav-item-1 d-flex pt-4">
                    <LuHome />
                    <p>Home</p>
                  </div>
                </div>
              </NavLink>

              {auth.user && auth.user.role === 1 && (
                <>
                  <NavLink
                    to="/dashboard/admin/users"
                    className="mx-3 text-dark"
                  >
                    <div data-bs-dismiss="offcanvas" aria-label="Close">
                      <div className="nav-item-1 d-flex pt-4">
                        <MdNotes />
                        <p>All Users</p>
                      </div>
                    </div>
                  </NavLink>
                  <NavLink
                    to="/dashboard/admin/request"
                    className="mx-3 text-dark"
                  >
                    <div data-bs-dismiss="offcanvas" aria-label="Close">
                      <div className="nav-item-1 d-flex pt-4">
                        <MdNotes />
                        <p>Requests</p>
                      </div>
                    </div>
                  </NavLink>
                </>
              )}
              {!auth.user ||
                (auth.user && auth.user.role !== 1 && (
                  <>
                    <NavLink
                      to="/dashboard/user/notification"
                      className="mx-3 text-dark"
                    >
                      <div data-bs-dismiss="offcanvas" aria-label="Close">
                        <div className="nav-item-1 d-flex pt-4">
                          <MdOutlineNotificationsActive />
                          <p>Notification</p>
                        </div>
                      </div>
                    </NavLink>
                    <NavLink
                      to="/dashboard/user/user-temp"
                      className="mx-3 text-dark"
                    >
                      <div data-bs-dismiss="offcanvas" aria-label="Close">
                        <div className="nav-item-1 d-flex pt-4">
                          <GoBrowser />
                          <p>Your Templates</p>
                        </div>
                      </div>
                    </NavLink>
                    <NavLink
                      to="/dashboard/user/request"
                      className="mx-3 text-dark"
                    >
                      <div data-bs-dismiss="offcanvas" aria-label="Close">
                        <div className="nav-item-1 d-flex pt-4">
                          <MdNotes />
                          <p>Requests</p>
                        </div>
                      </div>
                    </NavLink>
                  </>
                ))}
            </div>
          </div>
        </div>
      </div>

      {/* Second Navbar */}
      <ul className="nav nav-tabs pb-2">
        <div className="container second-nav px-4 py-2">
          <div className="d-flex align-items-center justify-content-center rightnav">
            <li className="nav-item">
              <div className=" ">
                <NavLink
                  to="/"
                  className={({ isActive }) =>
                    isActive ? "nav-link active" : "nav-link"
                  }
                >
                  <div className="nav-icon">
                    <img src={foryou} className="Ham" alt="For you" />
                  </div>{" "}
                  <div className="arrow"></div>
                </NavLink>{" "}
                <p className="text-center text-white">For you</p>
              </div>
            </li>
            <li className="nav-item">
              <div className="tab-icon">
                <NavLink
                  to="/template"
                  className={({ isActive }) =>
                    isActive ? "nav-link active" : "nav-link"
                  }
                >
                  <div className="nav-icon">
                    <img src={browse} className="Ham" alt="Explore" />
                  </div>{" "}
                  <div className="arrow"></div>
                </NavLink>{" "}
                <p className="text-center text-white">Explore</p>
              </div>
            </li>
            <li className="nav-item">
              <div className="tab-icon">
                <NavLink
                  to="/dashboard/user/notification"
                  className={({ isActive }) =>
                    isActive ? "nav-link active" : "nav-link"
                  }
                >
                  <div className="nav-icon">
                    <img src={notify} className="Ham" alt="Message" />
                  </div>{" "}
                  <div className="arrow"></div>
                </NavLink>{" "}
                <p className="text-center text-white">Notification</p>
              </div>
            </li>{" "}
            <li className="nav-item">
              <div className="tab-icon">
                <NavLink
                  to="/howto"
                  className={({ isActive }) =>
                    isActive ? "nav-link active" : "nav-link"
                  }
                >
                  <div className="nav-icon">
                    <img src={click} className="Ham" alt="Message" />
                  </div>{" "}
                  <div className="arrow"></div>
                </NavLink>{" "}
                <p className="text-center text-white">How To?</p>
              </div>
            </li>
          </div>
        </div>
      </ul>
    </>
  );
};

export default Header;
