// src/components/templates/Temp6.jsx
import React from 'react';

const Temp6 = ({ fatherName, greeting, specialMessage, memories }) => (
  <div className="fathers-day-template" style={styles.template}>
    <h1 style={styles.heading}>Happy Father's Day, {fatherName}!</h1>
    <p style={styles.greeting}>{greeting}</p>
    <p style={styles.message}>{specialMessage}</p>
    {memories && memories.length > 0 && (
      <div className="memories" style={styles.memories}>
        {memories.map((memory, index) => (
          <div key={index} style={styles.memory}>
            <img
              src={memory.photo}  // Assuming `memory.photo` is a valid URL
              alt={`Memory ${index + 1}`}
              style={styles.photo}
            />
            <p style={styles.caption}>{memory.caption}</p>
          </div>
        ))}
      </div>
    )}
  </div>
);

const styles = {
  template: {
    textAlign: 'center',
    padding: '20px',
    backgroundColor: '#e0f7fa',
    border: '1px solid #b2ebf2',
    borderRadius: '10px',
  },
  heading: {
    color: '#00796b',
    fontSize: '2em',
  },
  greeting: {
    color: '#004d40',
    fontSize: '1.2em',
  },
  message: {
    color: '#004d40',
    fontSize: '1.2em',
    margin: '20px 0',
  },
  memories: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  memory: {
    margin: '10px',
    textAlign: 'center',
  },
  photo: {
    width: '100px',
    height: '100px',
    objectFit: 'cover',
    borderRadius: '10px',
  },
  caption: {
    color: '#00796b',
    fontSize: '1em',
    marginTop: '5px',
  },
};

export default Temp6;
