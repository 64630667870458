import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import img from "../../imag/qrfinal.jpg";
import "./css/QRCode.css";

const QRCode = () => {
  const { userId, id } = useParams();
  const location = useLocation();
  const { price } = location.state;
  const [randomChars, setRandomChars] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [paymentUrl, setPaymentUrl] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchRandomChars = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API}/api/v1/wish/random/${userId}/${id}`
        );
        setRandomChars(response.data.randomChars);
        console.log("Random Chars Fetched: ", response.data.randomChars); // Debugging line
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchRandomChars();
  }, [userId, id]);

  useEffect(() => {
    const generatePaymentUrl = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API}/api/v1/payment/initiate`, // Backend endpoint to generate payment URL
          {
            amount: price,
            refId: randomChars
          }
        );
        console.log("Payment URL Response: ", response.data); // Debugging line

        // Assuming the backend returns the URL in `paymentUrl`
        setPaymentUrl(response.data.paymentUrl);
      } catch (err) {
        setError(err.message);
      }
    };

    if (randomChars && price) {
      generatePaymentUrl();
    }
  }, [randomChars, price]);

  const handleCopy = () => {
    if (randomChars) {
      navigator.clipboard
        .writeText(randomChars)
        .then(() => {
          toast.success("Copied!");
        })
        .catch((err) => {
          toast.error("Failed to copy!");
        });
    }
  };

  const handleHome = () => {
    const confirmed = window.confirm("Did you copy the code?");
    if (!confirmed) {
      return;
    }
    sessionStorage.setItem("showPopup", "true");
    navigate("/", { state: { showPopup: true } });
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const handleDelete = async () => {
    const confirmed = window.confirm("Are you sure you want to delete this request?");
    if (!confirmed) {
      return;
    }

    try {
      await axios.delete(`${process.env.REACT_APP_API}/api/v1/wish/delete/${id}`);
      toast.success("Birthday wish deleted successfully.");
      navigate("/");
    } catch (error) {
      toast.error("Failed to delete birthday wish.");
    }
  };

  return (
    <div>
      <Header />
      <div className="container p-3 pt-4 d-flex justify-content-center">
        <div className="card shadow p-4 text-center">
          <div className="card-body">
            <h1 className="card-title">Scan QR Code for Payment</h1>
            <div className="mt-4 bg-dark note">
              <h3 className="card-subtitle mb-2 text-danger">Note:</h3>
              <p className="card-text-qr text-danger">
                Make sure to copy the below code and paste it in the remarks
                during the payment process.
              </p>
            </div>
            <p className="card-text-1 mt-4">
              Your Code is: <span className="random-chars">{randomChars}</span>
            </p>
            <button className="btn btn-primary mb-3" onClick={handleCopy}>
              Copy
            </button>
            <h4 className="text-danger">Price: Rs. {price}</h4>
            <div className="d-flex justify-content-center">
              <img src={img} alt="QR Code" className="img-fluid" />
            </div>
            <div className="bg-info note mb-3">
              <h5 className="my-3"> Screenshot the above QR and pay</h5>
              <p>
                Your template will be approved after your payment is recorded
              </p>
            </div>
            <button className="btn btn-danger mb-3" onClick={handleDelete}>
              Delete
            </button>
            <button className="btn btn-success mb-3" onClick={handleHome}>
              Done
            </button>
            {paymentUrl ? (
              <button
                className="btn btn-success mb-3"
                onClick={() => window.open(paymentUrl, '_blank')}
              >
                Pay with eSewa
              </button>
            ) : (
              <div className="text-warning">Generating payment link...</div>
            )}
          </div>
        </div>
        <Toaster />
      </div>
      <Footer />
    </div>
  );
};

export default QRCode;
