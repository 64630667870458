import React, { useState, useEffect } from "react";
import UserMenu from "../../components/Menu/UserMenu";
import { useAuth } from "../../context/auth";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { IoCameraSharp } from "react-icons/io5"; // Import camera icon from react-icons

import defaultProfilePhoto from "../../imag/user/profile.jpg";

const UpdateProfile = () => {
  const navigate = useNavigate();
  // context
  const [auth, setAuth] = useAuth();
  // state
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [photo, setPhoto] = useState(null); // New state for storing the selected photo

  useEffect(() => {
    if (auth?.user) {
      const { email, name, phone } = auth.user;
      setName(name);
      setPhone(phone);
      setEmail(email);
    }
  }, [auth?.user]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const confirm = window.confirm("Are you sure you want to update your info?");
    if (!confirm) {
      return;
    }

    try {
      const formData = new FormData(); // Create a FormData object to send file data
      formData.append("name", name);
      formData.append("email", email);
      formData.append("phone", phone);
      formData.append("photo", photo); // Append the selected photo to the form data

      const { data } = await axios.put(
        `${process.env.REACT_APP_API}/api/v1/auth/profile`,
        formData
      );

      if (data?.error) {
        toast.error(data?.error);
      } else {
        setAuth({ ...auth, user: data?.updatedUser });
        let ls = localStorage.getItem("auth");
        ls = JSON.parse(ls);
        ls.user = data.updatedUser;
        localStorage.setItem("auth", JSON.stringify(ls));
        toast.success("Profile Updated Successfully");
        navigate("/dashboard/user/userdb");
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
    }
  };

  // Function to handle file input change
  const handlePhotoChange = (e) => {
    setPhoto(e.target.files[0]); // Set the selected photo to the state
  };

  // Function to trigger file input click
  const triggerFileInput = () => {
    document.getElementById("photoInput").click();
  };

  const userPhotoUrl = photo
    ? URL.createObjectURL(photo)
    : auth?.user
    ? `${process.env.REACT_APP_API}/api/v1/auth/user-photo/${auth.user._id}`
    : defaultProfilePhoto;

  return (
    <>
      <Header />
      <div className="container p-3 mt-5 pt-4">
        <div className="row">
          <div className="col-md-2">
            {/* <UserMenu /> */}
          </div>
          <div className="col-md-9 pt-4">
            <div className="form-container p-4 border rounded shadow-sm bg-light">
              <form onSubmit={handleSubmit}>
                <h4 className="title text-center mb-4">Update Profile</h4>
                {/* Profile photo and camera icon */}
                <div className="mb-3 text-center position-relative">
                  <img
                    src={userPhotoUrl}
                    alt="profile_photo"
                    height={"200px"}
                    className="img img-responsive rounded-circle"
                  />
                  <div
                    className="position-absolute top-50 start-50 translate-middle"
                    onClick={triggerFileInput}
                    style={{ cursor: "pointer" }}
                  >
                    <IoCameraSharp size={30} color="gray" />
                  </div>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handlePhotoChange}
                    className="form-control"
                    id="photoInput"
                    style={{ display: "none" }} // Hide the file input
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="exampleInputName" className="form-label">Name:</label>
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="form-control"
                    id="exampleInputName"
                    placeholder="Enter Your Name"
                    autoFocus
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="exampleInputEmail" className="form-label">Email:</label>
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="form-control"
                    id="exampleInputEmail"
                    placeholder="Enter Your Email"
                    disabled
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="exampleInputPhone" className="form-label">Phone:</label>
                  <input
                    type="text"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    className="form-control"
                    id="exampleInputPhone"
                    placeholder="Enter Your Phone"
                  />
                </div>

                <div className="d-flex">
                <button
                    type="button"
                    className="btn btn-warning me-2"
                    onClick={() => navigate("/dashboard/user/userdb")}
                  >
                    Go Back
                  </button>
                  <button
                    type="button"
                    className="btn btn-info me-2"
                    onClick={() => navigate("/dashboard/user/updatepassword")}
                  >
                    Change Password
                  </button>
                  <button type="submit" className="btn btn-primary me-2">
                    Update
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default UpdateProfile;
