import React, { useEffect, useRef, useState } from "react";
import styles from "./Joyful_Jubilee/Luminous.module.css";
import LocomotiveScroll from "locomotive-scroll";
import { Swiper, SwiperSlide } from "swiper/react";
import classNames from "classnames";
import "swiper/css";
import "swiper/css/effect-cards";
import logo from "./image/snapgreetlogo.png";
import photos0 from "./image/model1.jpg";
import photos1 from "./image/IMG_1191.jpg";
import iloveyou from "./image/i-love-you.png";
import hug from "./image/hug.gif";
import givelove from "./image/givelove.gif";

import glitter from "./Joyful_Jubilee/glitter-Photoroom.png";
import chat from "./image/chat.png";
import cake1 from "./image/youownhear.png";
import stars from "./Joyful_Jubilee/starts.png";

import music from "./Joyful_Jubilee.mp3";

import { EffectCards } from "swiper/modules";

const Temp1 = ({ name, nickname, description1, description2, photos }) => {
  const audioRef = useRef(null);
  const loaderRef = useRef(null);
  const buttonWrapperRef = useRef(null); // Use ref to ensure the buttonWrapper is accessible
  const [isStarted, setIsStarted] = useState(false);
  const [text, setText] = useState("");
  const [isTyping, setIsTyping] = useState(false);
  const message = `My dear love ${name}`; // Combine message and name
  const typeSpeed = 200; // Adjust the speed as needed

  // Typewriter effect function
  const typeWriter = (text, i = 0) => {
    if (i < text.length) {
      setText(text.substring(0, i + 1));
      setTimeout(() => typeWriter(text, i + 1), typeSpeed);
    } else {
      setIsTyping(false); // Mark typing as complete
      // Close the loader after typing is complete
      setTimeout(() => {
        loaderAnimation();
      }, 500); // Short delay to ensure typing is visible
    }
  };

  // Loader animation function
  const loaderAnimation = () => {
    const loader = loaderRef.current;
    const buttonWrapper = buttonWrapperRef.current; // Use ref to access the buttonWrapper
    if (loader && buttonWrapper) {
      loader.style.top = "-100%";
      const canvas = document.createElement("canvas");
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
      buttonWrapper.appendChild(canvas);
      createConfetti(canvas);
    }
  };

  // Function to create confetti
  const createConfetti = (canvas) => {
    const ctx = canvas.getContext("2d");
    const pieces = [];
    const colors = [
      "#ff0",
      "#0f0",
      "#00f",
      "#f00",
      "#0ff",
      "#f0f",
      "#ff6347",
      "#ee82ee",
      "#adff2f",
      "#40e0d0",
    ];

    for (let i = 0; i < 500; i++) {
      pieces.push({
        x: canvas.width / 2,
        y: canvas.height / 2,
        size: Math.random() * 8 + 2,
        speed: Math.random() * 5 + 2,
        angle: Math.random() * 2 * Math.PI,
        color: colors[Math.floor(Math.random() * colors.length)],
      });
    }

    const update = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      pieces.forEach((p) => {
        p.x += p.speed * Math.cos(p.angle);
        p.y += p.speed * Math.sin(p.angle);
        p.size *= 0.98;
        if (p.size < 1) return;
        ctx.fillStyle = p.color;
        ctx.beginPath();
        ctx.arc(p.x, p.y, p.size, 0, 2 * Math.PI);
        ctx.fill();
      });
      requestAnimationFrame(update);
    };

    update();
  };

  useEffect(() => {
    if (isStarted) {
      setIsTyping(true); // Start typing effect
      typeWriter(message);
    }

    // Initialize Locomotive Scroll
    const scroll = new LocomotiveScroll({
      el: document.querySelector("#main"),
      smooth: true,
    });
  }, [isStarted]);

  const startTemplate = () => {
    const audio = audioRef.current;
    audio.play();
    setIsStarted(true);
  };

  return (
    <div>
      <div id="loader" className={styles.loader} ref={loaderRef}>
        {!isStarted && (
          <div className="d-flex flex-column text-center align-items-center">
            <h3 className="text-white">Are you ready for the Magic?</h3>
            <img
              src={logo}
              className={classNames(styles.logoimgfirst, "img-fluid", "my-4")}
              alt="Logo"
            />
            <button
              onClick={startTemplate}
              className={classNames(styles.play_button, "mt-4", "px-5", "py-2")}
            >
              Let's go
            </button>
          </div>
        )}
        {isStarted && <h1>{text}</h1>}
      </div>

      <div id="main" className={styles.main}>
        <div id="page1" className={styles.page1}>
          <nav>
            <img src={logo} className={styles.logoimg} alt="Logo" />
          </nav>
          <div id="center" className={styles.center}>
            <div id="left" className={styles.left}>
              <h3>{description1}</h3>
              <div
                ref={buttonWrapperRef}
                className={styles.button_wrapper}
              ></div>
              <audio ref={audioRef} src={music} type="audio/mp3"></audio>
            </div>
            <div id="right" className={styles.right}>
              <h1 className={styles.name}>
                I LOVE <br />
                YOU <br />
              </h1>
              <h1 className={styles.username}> {nickname}</h1>
            </div>
          </div>
          <div id="hero-shape" className={styles.hero_shape}></div>
          <div className="container overflow-hidden">
            <div data-aos="slide-down" data-aos-offset="400">
              <img
                src={glitter}
                className={classNames(styles.glitter, "img-fluid")}
                alt="Glitter"
              />
            </div>
            <Swiper
              effect={"cards"}
              grabCursor={true}
              modules={[EffectCards]}
              className="mySwiper p-5"
            >
              <SwiperSlide>
                <div className="joyimage">
                  <img
                    src={photos[0]}
                    className={styles.saharsha}
                    alt="Photo 1"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src={photos[1]}
                  className={styles.saharsha}
                  alt="Photo 2"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src={photos[2]}
                  className={styles.saharsha}
                  alt="Photo 3"
                />
              </SwiperSlide>
            </Swiper>
          </div>
        </div>

        <div id="page2" className={styles.page_2}>
          <div id="moving-text" className={styles.moving_text}>
            <div className={styles.con}>
              <h1>LOVE</h1>
              <div className={styles.gola}></div>
              <h1>YOU</h1>
              <div className={styles.gola}></div>
              <h1>FOREVER</h1>
              <div className={styles.gola}></div>
              <h1>AND</h1>
              <div className={styles.gola}></div>
              <h1>ALWAYS</h1>
              <div className={styles.gola}></div>
            </div>
            <div className={styles.con}>
              <h1>LOVE</h1>
              <div className={styles.gola}></div>
              <h1>YOU</h1>
              <div className={styles.gola}></div>
              <h1>FOREVER</h1>
              <div className={styles.gola}></div>
              <h1>AND</h1>
              <div className={styles.gola}></div>
              <h1>ALWAYS</h1>
              <div className={styles.gola}></div>
            </div>
            <div className={styles.con}>
              <h1>LOVE</h1>
              <div className={styles.gola}></div>
              <h1>YOU</h1>
              <div className={styles.gola}></div>
              <h1>FOREVER</h1>
              <div className={styles.gola}></div>
              <h1>AND</h1>
              <div className={styles.gola}></div>
              <h1>ALWAYS</h1>
              <div className={styles.gola}></div>
            </div>
          </div>
          <div className={styles.page2_bottom}>
            <div data-aos="fade-in" data-aos-offset="150">
              <img
                src={hug}
                className={classNames(styles.glitter, "img-fluid")}
                alt="Glitter"
              />
            </div>{" "}
            <img src={chat} className="img-fluid chat" alt="Glitter" />
            <h1>{description2}</h1>{" "}
            {/* <img
              src={stars}
              className={classNames(styles.glitter, "img-fluid")}
              alt="Glitter"
              data-aos="fade-in"
              data-aos-offset="300"
            /> */}
            <div className="overflow-hidden">
              <div
                className="d-flex"
                data-aos="slide-down"
                data-aos-offset="150"
              >
                <img
                  src={iloveyou}
                  className={classNames(styles.glitter1, "img-fluid")}
                  alt="Glitter"
                />{" "}
                <img
                  src={iloveyou}
                  className={classNames(styles.glitter1, "img-fluid")}
                  alt="Glitter"
                />{" "}
                <img
                  src={iloveyou}
                  className={classNames(styles.glitter1, "img-fluid")}
                  alt="Glitter"
                />{" "}
                <img
                  src={iloveyou}
                  className={classNames(styles.glitter1, "img-fluid")}
                  alt="Glitter"
                />
              </div>
            </div>
            <div
              className={styles.cake}
              data-aos="slide-up"
              data-aos-offset="150"
            >
              <img src={cake1} />
              <img src={givelove} />
            </div>
          </div>{" "}
        </div>
        <div id="page5" className={styles.page5}></div>
      </div>

      <div id="footer" className={styles.footer}>
        <div id="footer-div" className={styles.footer_div}></div>
        <h1>
          FROM <br />
          SNAPGREET
        </h1>
        <div id="footer-bottom" className={styles.footer_bottom}></div>
      </div>
    </div>
  );
};

export default Temp1;
