import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import './ForgotPassword.css';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const navigate = useNavigate();

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${process.env.REACT_APP_API}/api/v1/auth/forgot-password`, { email });

      toast.success(response.data.message);
      
      setTimeout(() => {
        navigate('/login');
      }, 3000);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        toast.error('User not found');
      } else {
        toast.error('Error sending password reset email');
      }
    }
  };

  return (
    <div className="forgot-password-container">
      <h2 className="forgot-password-title">Forgot Password</h2>
      <Toaster />
      <form className="forgot-password-form" onSubmit={handleForgotPassword}>
        <div>
          <label className="forgot-password-label">Email</label>
          <input
            className="forgot-password-input"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <button className="forgot-password-button" type="submit">Send Reset Password Link</button>
      </form>
    </div>
  );
};

export default ForgotPassword;
