import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';

const PaymentFailure = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const refId = searchParams.get('refId');
  const amount = searchParams.get('amt');
  const navigate = useNavigate();

  const handleHome = () => {
    sessionStorage.setItem("showPopup", "true");
    navigate('/', { state: { showPopup: true } });
  };

  return (
    <div>
      <Header />
      <div className="container p-3 pt-4 text-center">
        <div className="card shadow p-4">
          <div className="card-body">
            <h1 className="card-title text-danger">Payment Failed</h1>
            <p className="card-text">Unfortunately, your payment was not successful.</p>
            <p className="card-text">Transaction ID: {refId}</p>
            <p className="card-text">Attempted Amount: Rs. {amount}</p>
            <button className="btn btn-primary mt-3" onClick={handleHome}>
              Go to Home
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PaymentFailure;
