import React, { useEffect, useState, Suspense } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import loadingGif from "../../imag/snapload.gif";
import { templateComponents } from "./TemplateMappings"; // Adjust the path as needed
<link rel="icon" href="%PUBLIC_URL%/favicon.ico" />

const TemplateView = () => {
  const { type, id } = useParams();
  const [template, setTemplate] = useState(null);
  const [TemplateComponent, setTemplateComponent] = useState(null);

  useEffect(() => {
    const fetchTemplate = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API}/api/v1/wish/template/${type}/${id}`
        );
        setTemplate(response.data);

        // Dynamically get the component based on templateType
        const component = templateComponents[response.data.templateType];
        if (component) {
          setTemplateComponent(() => component);
        }
      } catch (error) {
        console.error("Error fetching template:", error);
      }
    };

    if (type && id) {
      fetchTemplate();
    }
  }, [type, id]);

  if (!template || !TemplateComponent) {
    return (
      <div className="loading-container">
        <img src={loadingGif} alt="Loading..." className="loading-gif" />
      </div>
    );
  }

  // Construct photo URLs
  const photoURLs = template.photoURLs.map(
    (photoId) => `${process.env.REACT_APP_API}${photoId}`
  );

  return (
    <Suspense fallback={<div>Loading template...</div>}>
      <TemplateComponent
        name={template.name}
        nickname={template.nickname}
        description1={template.description1}
        description2={template.description2}
        templateType={template.templateType}
        photos={photoURLs} // Pass the photo URLs
      />
    </Suspense>
  );
};

export default TemplateView;
