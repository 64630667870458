import React, { useState } from "react";
import axios from "axios";
import { useNavigate, useLocation, NavLink } from "react-router-dom";
import toast from "react-hot-toast";
import pethouse from "../../imag/fullsnap.png";
import {
  MdOutlineAlternateEmail,
  MdLockOpen,
  MdOutlinePhone,
} from "react-icons/md";
import { CgProfile } from "react-icons/cg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import loadingGif from "../../imag/logo.gif"; // Import loading GIF

const Register = () => {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const role = queryParams.get("role");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [phone, setPhone] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false); // New loading state
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if password and confirmPass are the same
    if (password !== confirmPass) {
      toast.error("Passwords do not match");
      return;
    }

    // Check if password is at least 6 characters long
    if (password.length < 6) {
      toast.error("Password should be at least 6 characters long");
      return;
    }

    // Validate email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      toast.error("Please enter a valid email address");
      return;
    }

    setLoading(true); // Set loading to true when starting the request

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API}/api/v1/auth/register`,
        {
          name,
          email,
          password,
          confirmPass,
          phone,
        }
      );
      if (res.data.success) {
        toast.success(res.data.message);
        navigate("/login");
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      console.error(error);
      toast.error("Something went wrong");
    } finally {
      setLoading(false); // Set loading to false when request completes
    }
  };

  return (
    <div className="register container-fluid">
      {loading ? (
        <div className="text-center">
          <img src={loadingGif} alt="Loading..." className="loading-gif" />
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          <div className="row justify-content-center align-items-center loginpage">
            <h1
              className="purple text-center"
              data-aos="slide-up"
              data-aos-offset="100"
            >
              Register
            </h1>
            <div className="col-md-4 px-3">
              <div
                className="mb-3 position-relative"
                data-aos="slide-up"
                data-aos-offset="100"
              >
                <p>Enter your name</p>
                <span className="input-icons">
                  <CgProfile />
                </span>
                <input
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="input"
                  placeholder="Eg: Snapgreeter"
                  required
                  style={{ fontSize: "16px" }}
                />
              </div>
              <div
                className="mb-3 position-relative"
                data-aos="slide-up"
                data-aos-offset="100"
              >
                <p>Enter your email</p>
                <span className="input-icons">
                  <MdOutlineAlternateEmail />
                </span>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="input"
                  placeholder="Eg: snapgreeter@gmail.com"
                  required
                  style={{ fontSize: "16px" }}
                />
              </div>
            </div>
            <div className="col-md-4 px-3">
              <div
                className="mb-3 position-relative"
                data-aos="slide-up"
                data-aos-offset="100"
              >
                <p>Enter password</p>
                <span className="input-icons">
                  <MdLockOpen />
                </span>
                <input
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="input"
                  placeholder="********"
                  required
                  style={{ fontSize: "16px" }}
                />
                <span
                  className="password-toggle-icon"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                </span>
              </div>
              <div
                className="mb-3 position-relative"
                data-aos="slide-up"
                data-aos-offset="100"
              >
                <p>Confirm password</p>
                <span className="input-icons">
                  <MdLockOpen />
                </span>
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  value={confirmPass}
                  onChange={(e) => setConfirmPass(e.target.value)}
                  className="input"
                  placeholder="********"
                  required
                  style={{ fontSize: "16px" }}
                />
                <span
                  className="password-toggle-icon"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                >
                  <FontAwesomeIcon
                    icon={showConfirmPassword ? faEyeSlash : faEye}
                  />
                </span>
              </div>
            </div>
            <div className="col-md-4 px-3">
              <div
                className="mb-3 position-relative"
                data-aos="slide-up"
                data-aos-offset="100"
              >
                <p>Enter your number</p>
                <span className="input-icons">
                  <MdOutlinePhone />
                </span>
                <input
                  type="text"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  className="input"
                  placeholder="9841123456"
                  required
                  style={{ fontSize: "16px" }}
                />
              </div>
              <button
                type="submit"
                className="btn-more px-3 py-2"
                data-aos="slide-up"
                data-aos-offset="100"
              >
                Submit
              </button>
              <p>
                <br />
                Already have an account?
                <NavLink
                  to="/login"
                  className="purple px-1 mb-4"
                  data-aos="slide-up"
                  data-aos-offset="50"
                >
                  Log in
                </NavLink>
              </p>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default Register;
