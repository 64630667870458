import React, { useState } from "react";
import axios from "axios";
import { useNavigate, useLocation, NavLink } from "react-router-dom";
import toast from "react-hot-toast";
import { useAuth } from "../../context/auth";
import pethouse from "../../imag/snapgreetlogo.png";
import { MdOutlineAlternateEmail, MdLockOpen } from "react-icons/md";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import loadingGif from "../../imag/logo.gif";
import { GoogleLogin } from "@react-oauth/google";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [auth, setAuth] = useAuth();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API}/api/v1/auth/login`,
        { email, password }
      );
      if (res.data && res.data.success) {
        toast.success(res.data.message);
        setAuth({
          user: res.data.user,
          token: res.data.token,
        });
        localStorage.setItem("auth", JSON.stringify(res.data));
        navigate(location.state || "/");
      } else {
        toast.error(
          res.data.message || "Something went wrong. Please try again later."
        );
      }
    } catch (error) {
      console.error(error);
      toast.error("Something went wrong. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const handleGoogleSuccess = async (response) => {
    setLoading(true);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API}/api/v1/auth/google`,
        { idToken: response.credential }
      );
      if (res.data && res.data.success) {
        toast.success(res.data.message);
        setAuth({
          user: res.data.user,
          token: res.data.token,
        });
        localStorage.setItem("auth", JSON.stringify(res.data));
        navigate(location.state || "/");
      } else {
        toast.error("Something went wrong with Google login.");
      }
    } catch (error) {
      console.error(error);
      toast.error("Failed to login with Google. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleGoogleFailure = () => {
    toast.error("Failed to login with Google. Please try again.");
    setLoading(false);
  };

  return (
    <div className="register row loginpage">
      {!loading && (
        <div className="col-lg-4 text-center mb-4">
          <img
            src={pethouse}
            className="img-fluid"
            data-aos="fade"
            data-aos-offset="100"
            alt="Pet House"
          />
          <p className="slogan">
            Send <span className="love">Digital Greetings</span>,<br />
            Create <span className="love">Joyful Moments</span>
          </p>
        </div>
      )}
      <div className="col-lg-4">
        {loading ? (
          <div className="text-center loading-container">
            <img src={loadingGif} alt="Loading..." className="loading-gif" />
          </div>
        ) : (
          <form onSubmit={handleSubmit}>
            <div
              className="mb-3 position-relative"
              data-aos="slide-up"
              data-aos-offset="100"
            >
              <p>Email</p>
              <span className="input-icons">
                <MdOutlineAlternateEmail />
              </span>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="input"
                id="exampleInputEmail1"
                placeholder="Enter Email"
                required
                style={{ fontSize: "16px" }} // Add this line
              />
            </div>
            <div
              className="position-relative"
              data-aos="slide-up"
              data-aos-offset="100"
            >
              <p>Password</p>
              <span className="input-icons">
                <MdLockOpen />
              </span>
              <input
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="input"
                id="exampleInputPassword1"
                placeholder="Enter Password"
                required
                style={{ fontSize: "16px" }} // Add this line
              />
              <span
                className="password-toggle-icon"
                onClick={() => setShowPassword(!showPassword)}
              >
                <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
              </span>
            </div>
            <div className="mb-4" data-aos="slide-up" data-aos-offset="100">
              <NavLink to="/forgot-password" className="btn">
                Forgot Password?
              </NavLink>
            </div>
            <button
              type="submit"
              className="btn-more px-5 py-3 btn-login"
              data-aos="slide-up"
              data-aos-offset="50"
            >
              Login
            </button>
            <p>
              <br />
              Don't have an account?
              <NavLink
                to="/register"
                className="purple px-1 mb-4"
                data-aos="slide-up"
                data-aos-offset="50"
              >
                Register
              </NavLink>
            </p>
            <div className="text-center mt-4">
              <GoogleLogin
                onSuccess={handleGoogleSuccess}
                onError={handleGoogleFailure}
              />
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default Login;
