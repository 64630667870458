import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom"; // Correctly import useParams here
import axios from "axios";
import { useAuth } from "../../context/auth";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import imagePaths from "../TempComponent/template-previews/imagePaths"; // Import the image paths
import AniversaryTemplateInfo from "../TempComponent/Anniversary/TemplateInfo";
import BirthdayTemplateInfo from "../TempComponent/Birthday/TemplateInfo";
import FathersDayTemplateInfo from "../TempComponent/FathersDay/TemplateInfo";
import RakshyaBandanTemplateInfo from "../TempComponent/Rakshya_Bandann/TemplateInfo";

const UserRequest = () => {
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [auth] = useAuth();
  const { templateType } = useParams(); // Use useParams to get the templateType
  
  const getImageSrc = (templateType) => {
    // Loop through the categories and their images
    for (const [category, images] of Object.entries(imagePaths)) {
      // Check if any image file name includes the template type
      const imagePath = Object.keys(images).find((img) =>
        img.includes(templateType)
      );
      if (imagePath) {
        return images[imagePath]; // Return the matching image path
      }
    }
    return "";
  };

  const getFallbackImageSrc = (templateType) => {
    return `/template-previews/${templateType}.png`;
  };

  const getTemplateInfo = (templateType) => {
    if (templateType in AniversaryTemplateInfo) {
      return AniversaryTemplateInfo[templateType];
    } else if (templateType in BirthdayTemplateInfo) {
      return BirthdayTemplateInfo[templateType];
    } else if (templateType in FathersDayTemplateInfo) {
      return FathersDayTemplateInfo[templateType];
    } else if (templateType in RakshyaBandanTemplateInfo) {
      return RakshyaBandanTemplateInfo[templateType];
    }
    return { title: 'Unknown Template' }; // Default fallback title
  };
  

  useEffect(() => {
    const fetchRequests = async () => {
      setLoading(true); // Start loading
      setError(null); // Clear previous error

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API}/api/v1/wish/requests/${auth.user._id}`
        );
        setRequests(response.data);
      } catch (error) {
        setError(error.message); // Set error message
      } finally {
        setLoading(false); // Stop loading regardless of success or failure
      }
    };

    if (auth.user) {
      fetchRequests();
      getTemplateInfo();
    } else {
      setLoading(false); // If no user, stop loading
    }
  }, [auth.user, templateType]); // Depend on auth.user and templateType

  return (
    <>
      <Header />
      <div className="container p-3 pt-4">
        <div className="row">
          <div className="col-md-3">
            {/* <UserMenu /> */}
          </div>
          <div className="col-md-9 pt-4">
            <h2>Pending Requests</h2>
            {loading && <p className="loading">Loading...</p>}
            {error && <p className="error">Error: {error}</p>}
            {!loading && !error && requests.length === 0 && (
              <p>No pending requests found.</p>
            )}
            {!loading && !error && requests.length > 0 && (
              <div className="cards">
                {requests.map((request) => {
                  const templateInfo = getTemplateInfo(request.templateType);
  
                  return (
                    <div className="card mb-3" key={request._id}>
                      <div className="card-body">
                        <img
                          src={getImageSrc(request.templateType)}
                          alt={`${request.templateType} preview`}
                          className="card-img-top template-image"
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = getFallbackImageSrc(request.templateType);
                          }}
                        />
                        <h3 className="card-title">Name : {request.name}</h3>
                        <p className="card-texts">Birthday Wish : {request.description1}</p>
                        <p className="card-texts">Template : {templateInfo ? templateInfo.title : 'Unknown Template'}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
  
};

export default UserRequest;
