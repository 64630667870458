import React, { useEffect, useRef, useState } from "react";
import styles from "./Endless.module.css";
import LocomotiveScroll from "locomotive-scroll";
import html2canvas from "html2canvas";
import logo from "../../../../imag/snapgreetlogo.png";
import music from "./Radiant_Revelary.mp3";
import cake from "./image/lovedefie.gif";
import gifts from "./image/brotherandsister.gif";
import ornaments from "./image/tobrother.png";
import letscelebrate from "./image/brothersister.png";
import flower from "./image/iris.png";
import sakura from "./image/sakura.png";
import birthday from "./image/heart.png";
import apest from "./image/chat.png";
import happybday from "./image/givelove.gif";
import classNames from "classnames";
import {
  FaEnvelope,
  FaFacebook,
  FaSnapchat,
  FaYoutube,
  FaInstagram,
} from "react-icons/fa";
const Temp1 = ({ name, nickname, description1, description2, photos }) => {
  const [showInitialContent, setShowInitialContent] = useState(true);
  const buttonWrapperRef = useRef(null);

  useEffect(() => {
    if (!showInitialContent) {
      const scroll = new LocomotiveScroll({
        el: document.querySelector("#main"),
        smooth: true,
      });
      const createConfetti = (canvas) => {
        const ctx = canvas.getContext("2d");
        const pieces = [];
        const colors = [
          "#ff0",
          "#0f0",
          "#00f",
          "#f00",
          "#0ff",
          "#f0f",
          "#ff6347",
          "#ee82ee",
          "#adff2f",
          "#40e0d0",
        ];

        for (let i = 0; i < 1000; i++) {
          pieces.push({
            x: canvas.width / 2,
            y: canvas.height / 2,
            size: Math.random() * 8 + 2,
            speed: Math.random() * 5 + 2,
            angle: Math.random() * 2 * Math.PI,
            color: colors[Math.floor(Math.random() * colors.length)],
          });
        }

        const update = () => {
          ctx.clearRect(0, 0, canvas.width, canvas.height);
          pieces.forEach((p) => {
            p.x += p.speed * Math.cos(p.angle);
            p.y += p.speed * Math.sin(p.angle);
            p.size *= 0.98;
            if (p.size < 1) return;
            ctx.fillStyle = p.color;
            ctx.beginPath();
            ctx.arc(p.x, p.y, p.size, 0, 2 * Math.PI);
            ctx.fill();
          });
          requestAnimationFrame(update);
        };

        update();
      };
      const loaderAnimation = () => {
        const loader = document.querySelector("#loader");
        setTimeout(() => {
          loader.style.top = "-100%";
          const buttonWrapper = document.querySelector(".button-wrapper");
          if (buttonWrapper) {
            const canvas = document.createElement("canvas");
            canvas.width = window.innerWidth;
            canvas.height = window.innerHeight;
            buttonWrapper.appendChild(canvas);
            createConfetti(canvas);
          } else {
            console.error("button_wrapper element not found");
          }
        }, 4200);
      };

      loaderAnimation();
    }
  }, [showInitialContent]);

  const startTemplate = () => {
    setShowInitialContent(false);
    const audio = new Audio(music);
    audio.play();
  };

  // Function to capture screenshot
  const captureScreenshot = async (id, filename) => {
    const element = document.getElementById(id);
    if (element) {
      // Wait for all images inside the element to load
      const images = element.getElementsByTagName("img");
      const imageLoadPromises = Array.from(images).map((img) => {
        return new Promise((resolve) => {
          if (img.complete) {
            resolve();
          } else {
            img.onload = resolve;
            img.onerror = resolve; // Resolve even if there is an error
          }
        });
      });

      await Promise.all(imageLoadPromises);

      // After all images have loaded, capture the screenshot
      const canvas = await html2canvas(element, {
        useCORS: true, // Ensure that CORS issues are handled
      });
      const link = document.createElement("a");
      link.href = canvas.toDataURL("image/png");
      link.download = `${filename}.png`;
      link.click();
    }
  };

  return (
    <div>
      {showInitialContent ? (
        <div className={styles.loader1}>
          <div className="d-flex flex-column text-center align-items-center ">
            <h3 className="text-white">For the best brother in the world</h3>
            <img
              src={logo}
              className={classNames(styles.logoimgfirst, "img-fluid", "my-4")}
              alt="Logo"
            />
            <button
              onClick={startTemplate}
              className={classNames(styles.play_button, "mt-4", "px-5", "py-2")}
            >
              Let's go
            </button>
          </div>
        </div>
      ) : (
        <>
          <div id="loader" className={styles.loader}>
            <h1>HAPPY</h1>
            <h1>
              RAKHSA<br></br>BANDHAN
            </h1>
            <h1 className={styles.name}>{name}</h1>
          </div>
          <div id="main" className={styles.main}>
            <div id="page1" className={styles.page1}>
              <div className={styles.firstpage} id="firstpage">
                <nav className={styles.nav}>
                  <img src={logo} className={styles.logoimg} alt="Logo" />
                </nav>
                <div id="center" className={styles.center}>
                  <div id="left" className={styles.left}>
                    <h3 className={styles.capitalize}>{description1}</h3>
                    <div className="button-wrapper"></div>
                  </div>
                  <div id="right">
                    <h1 className={styles.name}>
                      LOT'S <br />
                      OF LOVE <br />
                    </h1>
                    <h1 className={styles.username}> {nickname}</h1>
                  </div>
                </div>
              </div>
              <div id="hero-shape"></div>

              <div className="image-section" id="imagesection">
                <div className="container pt-4">
                  <div className="row align-items-center justify-content-center">
                    <div className="col-lg-4 col-6">
                      {photos[1] && (
                        <img
                          src={photos[1]}
                          // data-aos="fade-in"
                          // data-aos-offset="100"
                          className={styles.saharsha}
                          alt="Photo 2"
                        />
                      )}
                      <div className={styles.flower1}>
                        <img
                          src={birthday}
                          data-aos="zoom-in"
                          data-aos-offset="100"
                          className="img-fluid"
                          alt="Photo 1"
                        />{" "}
                      </div>
                      {photos[2] && (
                        <img
                          src={photos[2]}
                          // data-aos="fade-in"
                          // data-aos-offset="150"
                          className={styles.saharsha}
                          alt="Photo 3"
                        />
                      )}
                    </div>
                    <div className="col-lg-4 col-6 d-flex flex-column justify-content-center">
                      {" "}
                      <div className={styles.flower1}>
                        <img
                          src={flower}
                          data-aos="zoom-in"
                          data-aos-offset="150"
                          className="img-fluid"
                          alt="Photo 1"
                        />{" "}
                      </div>
                      {photos[0] && (
                        <img
                          src={photos[0]}
                          data-aos="fade-in"
                          data-aos-offset="150"
                          className={styles.saharsha}
                          alt="Photo 1"
                        />
                      )}{" "}
                      <div className={styles.flower2}>
                        <img
                          src={sakura}
                          data-aos="zoom-in"
                          data-aos-offset="100"
                          className="img-fluid"
                          alt="Photo 1"
                        />{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="page2" className={styles.page2}>
              <div id="moving-text" className={styles.moving_text}>
                <div className={styles.con}>
                  <h1>NOTHING</h1>
                  <div className={styles.gola}></div>
                  <h1>CAN</h1>
                  <div className={styles.gola}></div>
                  <h1>MAKE</h1>
                  <div className={styles.gola}></div>
                  <h1>US</h1>
                  <div className={styles.gola}></div>
                  <h1>APART</h1>
                  <div className={styles.gola}></div>
                </div>
                <div className={styles.con}>
                  <h1>NOTHING</h1>
                  <div className={styles.gola}></div>
                  <h1>CAN</h1>
                  <div className={styles.gola}></div>
                  <h1>MAKE</h1>
                  <div className={styles.gola}></div>
                  <h1>US</h1>
                  <div className={styles.gola}></div>
                  <h1>APART</h1>
                  <div className={styles.gola}></div>
                </div>
                <div className={styles.con}>
                  <h1>NOTHING</h1>
                  <div className={styles.gola}></div>
                  <h1>CAN</h1>
                  <div className={styles.gola}></div>
                  <h1>MAKE</h1>
                  <div className={styles.gola}></div>
                  <h1>US</h1>
                  <div className={styles.gola}></div>
                  <h1>APART</h1>
                  <div className={styles.gola}></div>
                </div>
              </div>

              <div className="overflow-hidden container pb-5">
                <img src={happybday} className="img-fluid" alt="Ornaments" />
                <img
                  src={letscelebrate}
                  className="img-fluid"
                  alt="Let's Celebrate"
                  data-aos="zoom-in"
                  data-aos-offset="150"
                />
                <div id="page2-bottom" className={styles.page2_bottom}>
                  <img
                    src={apest}
                    className="img-fluid pb-2"
                    alt="Let's Celebrate"
                  />
                  <h1>{description2}</h1>
                </div>
              </div>

              <div className={styles.gift}>
                <img
                  src={gifts}
                  className="img-fluid mt-5"
                  data-aos="slide-right"
                  data-aos-offset="150"
                  alt="Gifts"
                />
                <img
                  src={ornaments}
                  className="img-fluid mt-5"
                  data-aos="zoom-in"
                  data-aos-offset="150"
                  alt="Ornaments"
                />
              </div>
            </div>
            <div className="downbtn text-center ">
              <div className="d-flex justify-content-center align-items-center">
                <a
                  className="btn btn-social"
                  href=""
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fab fa-facebook-f">
                    <FaFacebook />
                  </i>
                </a>
                <a
                  className="btn btn-social"
                  href=""
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fab fa-youtube">
                    <FaSnapchat />
                  </i>
                </a>

                <a
                  className="btn btn-social"
                  href="https://www.instagram.com/snap.greet/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fab fa-instagram-in">
                    <FaInstagram />
                  </i>
                </a>
              </div>
              <div id="page5" className={styles.page5}></div>
            </div>{" "}
          </div>{" "}
          <div id="footer" className={styles.footer}>
            <div id="footer-div" className={styles.footer_div}></div>
            <h1>
              FROM <br />
              SNAPGREET
            </h1>
            <div id="footer-bottom" className={styles.footer_bottom}></div>
          </div>
        </>
      )}
    </div>
  );
};

export default Temp1;
