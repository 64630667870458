import mockup from "../../imag/Group 24.png";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";
import video from "../../imag/adsnap.MOV";
// import required modules

const Header = () => {
  return (
    <div>
      <div className="hero-banner everbanner">
        <div className="hero-text">
          <div className="container overflow-hidden">
            <div className="row justify-content-between pt-5 pb-5">
              <h1
                className="text-white mb-4 mt-3 text-center herotext"
                style={{ width: "100%" }}
                data-aos="slide-up"
                data-aos-offset="100"
              >
                Greetings With <span className="purpletxt">Website</span>, Ever
                Heard One?{" "}
              </h1>
              <div
                className="col-5 col-sms-5 mockup mock1"
                data-aos="slide-up"
                data-aos-offset="100"
              >
                <video autoPlay muted loop playsInline controls={false}>
                  <source src={video} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>{" "}
              </div>
              <div className="col-6 col-sms-5 mt-1 mb-4 mockup flex-column">
                <h3 className="mb-4">
                  Have you ever experienced the{" "}
                  <span className="purpletxt">magic</span> of sending a greeting
                  with a <span className="purpletxt">website</span>?
                </h3>
                <p
                  className="text-white mb-4"
                  data-aos="slide-up"
                  data-aos-offset="100"
                >
                  With Snapgreet, you can choose from a variety of stunning
                  templates, add personalized messages, photos, and even music
                  to create a unique, heartfelt greeting.{" "}
                </p>{" "}
                <p
                  className="text-white mb-2"
                  data-aos="slide-up"
                  data-aos-offset="100"
                >
                  Whether it's a birthday, anniversary, or any special occasion,
                  Snapgreet makes it unforgettable. Say goodbye to traditional
                  cards and hello to a new era of celebration. Try Snapgreet
                  today and spread joy like never before!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
