// src/utils/dynamicImports.js

const importAll = (requireContext) => {
  let components = {};
  requireContext.keys().forEach((fileName) => {
    const componentName = fileName.replace('./', '').replace('.jsx', '');
    components[componentName] = requireContext(fileName).default;
  });
  return components;
};

// Import all components from specified directories
const birthdayComponents = importAll(require.context('../TempComponent/Birthday/BirthdayTemplates', false, /\.jsx$/));
const anniversaryComponents = importAll(require.context('../TempComponent/Anniversary/TemplatesAniversary', false, /\.jsx$/));
const fathersDayComponents = importAll(require.context('../TempComponent/FathersDay/FathersDayTemp', false, /\.jsx$/));
const RakshyaBandanComponents = importAll(require.context('../TempComponent/Rakshya_Bandann/RakshyaBandan', false, /\.jsx$/));

// Combine all components into one object
export const templateComponents = {
  ...birthdayComponents,
  ...anniversaryComponents,
  ...fathersDayComponents,
  ...RakshyaBandanComponents,
};
