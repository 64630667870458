import React, { useState } from "react";
import UserMenu from "../../components/Menu/UserMenu";
import { useAuth } from "../../context/auth";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Footer from "../../components/Footer";
import Header from "../../components/Header";

const Password = () => {
  const navigate = useNavigate();
  const [auth, setAuth] = useAuth();
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  const handlePasswordUpdate = async (e) => {
    e.preventDefault();
    if (newPassword.length < 6) {
      toast.error("New password must be at least 6 characters long");
      return;
    }
    if (newPassword !== confirmNewPassword) {
      toast.error("Passwords do not match");
      return;
    }
    const confirm = window.confirm("Are you sure you want to change your password?");
    if (!confirm) {
      return;
    }
    try {
      const formData = {
        newPassword,
        confirmNewPassword,
      };

      const { data } = await axios.put(`${process.env.REACT_APP_API}/api/v1/auth/updatePass`, formData);

      if (data?.error) {
        toast.error(data.error); // Display error message from backend
      } else {
        setAuth({ ...auth, user: data.updatedUser });
        const ls = JSON.parse(localStorage.getItem("auth"));
        const updatedLs = { ...ls, user: data.updatedUser };
        localStorage.setItem("auth", JSON.stringify(updatedLs));
        toast.success("Password updated successfully");
        navigate("/dashboard/user/userdb");
      }
    } catch (error) {
      console.log(error);
      if (error.response && error.response.data && error.response.data.error) {
        toast.error(error.response.data.error); // Display specific error message from backend response
      } else {
        toast.error("Something went wrong"); // Fallback generic error message
      }
    }
  };

  return (
    <>
      <Header />
      <div className="container m-3 p-3 mt-5 pt-4">
        <div className="row">
          <div className="col-md-3">
            {/* <UserMenu /> */}
          </div>
          <div className="col-md-9 pt-4">
            <div className="form-container p-4 border rounded shadow-sm bg-light">
              <form onSubmit={handlePasswordUpdate}>
                <h4 className="title text-center mb-4">Update Password</h4>
                <div className="mb-3">
                  <label htmlFor="newPassword" className="form-label">New Password:</label>
                  <input
                    type="password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    className="form-control"
                    id="newPassword"
                    placeholder="New Password"
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="confirmNewPassword" className="form-label">Confirm New Password:</label>
                  <input
                    type="password"
                    value={confirmNewPassword}
                    onChange={(e) => setConfirmNewPassword(e.target.value)}
                    className="form-control"
                    id="confirmNewPassword"
                    placeholder="Confirm New Password"
                  />
                </div>
                <div className="d-flex">
                <button
                    type="button"
                    className="btn btn-warning me-2"
                    onClick={() => navigate("/dashboard/user/updateprofile")}
                  >
                    Go Back
                  </button>
                  <button type="submit" className="btn btn-primary">
                    Update Password
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Password;
