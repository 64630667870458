import React from "react";
import styles from "./ChoosingUs.module.css";
import innovative from "../../../imag/innovative.png";
import easy from "../../../imag/easy.png";
import instant from "../../../imag/instant.png";
import joy from "../../../imag/joy.png";

const ChoosingUs = () => {
  return (
    <div
      className="mb-4 mt-4"
      style={{ backgroundColor: "#CCE9F1", borderRadius: "3rem" }}
    >
      <div className="container overflow-hidden text-center mb-4">
        <h3
          data-aos="slide-right"
          data-aos-offset="50"
          className={styles.heading}
        >
          Why Digital Greeting from{" "}
          <span className={styles.darkpurple}>Snapgreet</span>?{" "}
        </h3>

        <div
          className={`row ${styles.sub} align-items-center justify-content-center`}
        >
          <div data-aos="fade-right" className="col-md-5">
            <img
              src={innovative}
              className={styles.chooseicon}
              alt="experience"
            />
            <p className={styles.logo_head}>Innovative </p>
            <p className={styles.logo_text}>
              Say goodbye to traditional cards and hello to a new era of
              celebration.{" "}
            </p>
          </div>
          <div data-aos="fade" className="col-md-5">
            <img src={easy} className={styles.chooseicon} alt="experience" />
            <p className={styles.logo_head}>Easy to Use </p>
            <p className={styles.logo_text}>
              Simple, user-friendly platform for creating and sending greetings.{" "}
            </p>
          </div>
          <div data-aos="fade-left" className="col-md-5">
            <img src={instant} className={styles.chooseicon} alt="experience" />
            <p className={styles.logo_head}>Instant Delivery</p>
            <p className={styles.logo_text}>
              Send your digital greetings instantly, no postage required.{" "}
            </p>
          </div>{" "}
          <div data-aos="fade-left" className="col-md-5">
            <img src={joy} className={styles.chooseicon} alt="experience" />
            <p className={styles.logo_head}>Spread Joy</p>
            <p className={styles.logo_text}>
              Create joyful moments and share them with loved ones.{" "}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChoosingUs;
