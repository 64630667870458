import React, { useEffect, useRef, useState } from "react";
import styles from "./Timeless.module.css";
import LocomotiveScroll from "locomotive-scroll";
import logo from "../../../../imag/snapgreetlogo.png";
import music from "./Joyful_Jubilee.mp3";
import cake from "./image/givelove.gif";
import gifts from "./image/lovedefie.gif";
import ornaments from "./image/hug.gif";
import letscelebrate from "./image/lovetobe.png";
import boy2 from "./image/boy2.jpg";
import chat from "./image/chat.png";

import classNames from "classnames";

const Temp1 = ({ name, nickname, description1, description2, photos }) => {
  const [showInitialContent, setShowInitialContent] = useState(true);
  const buttonWrapperRef = useRef(null); // Use ref to ensure the buttonWrapper    is accessible

  useEffect(() => {
    if (!showInitialContent) {
      // Initialize Locomotive Scroll
      const scroll = new LocomotiveScroll({
        el: document.querySelector("#main"),
        smooth: true,
      });

      // Function to create confetti
      const createConfetti = (canvas) => {
        const ctx = canvas.getContext("2d");
        const pieces = [];
        const colors = [
          "#ff0",
          "#0f0",
          "#00f",
          "#f00",
          "#0ff",
          "#f0f",
          "#ff6347",
          "#ee82ee",
          "#adff2f",
          "#40e0d0",
        ];

        for (let i = 0; i < 1000; i++) {
          pieces.push({
            x: canvas.width / 2,
            y: canvas.height / 2,
            size: Math.random() * 8 + 2,
            speed: Math.random() * 5 + 2,
            angle: Math.random() * 2 * Math.PI,
            color: colors[Math.floor(Math.random() * colors.length)],
          });
        }

        const update = () => {
          ctx.clearRect(0, 0, canvas.width, canvas.height);
          pieces.forEach((p) => {
            p.x += p.speed * Math.cos(p.angle);
            p.y += p.speed * Math.sin(p.angle);
            p.size *= 0.98;
            if (p.size < 1) return;
            ctx.fillStyle = p.color;
            ctx.beginPath();
            ctx.arc(p.x, p.y, p.size, 0, 2 * Math.PI);
            ctx.fill();
          });
          requestAnimationFrame(update);
        };

        update();
      };

      // Loader animation function
      const loaderAnimation = () => {
        const loader = document.querySelector("#loader");
        setTimeout(() => {
          loader.style.top = "-100%";
          const buttonWrapper = document.querySelector(".button-wrapper");
          if (buttonWrapper) {
            const canvas = document.createElement("canvas");
            canvas.width = window.innerWidth;
            canvas.height = window.innerHeight;
            buttonWrapper.appendChild(canvas);
            createConfetti(canvas);
          } else {
            console.error("button_wrapper element not found");
          }
        }, 4200);
      };

      loaderAnimation();
    }
  }, [showInitialContent]);

  const startTemplate = () => {
    setShowInitialContent(false);
    const audio = new Audio(music);
    audio.play();
  };

  useEffect(() => {}, [photos]);

  return (
    <div>
      {showInitialContent ? (
        <div className={styles.loader1}>
          <div className="d-flex flex-column text-center align-items-center ">
            <h3 className="text-white">Are you ready for the Magic?</h3>
            <img
              src={logo}
              className={classNames(styles.logoimgfirst, "img-fluid", "my-4")}
              alt="Logo"
            />
            <button
              onClick={startTemplate}
              className={classNames(styles.play_button, "mt-4", "px-5", "py-2")}
            >
              Let's go
            </button>
          </div>
        </div>
      ) : (
        <>
          <div id="loader" className={styles.loader}>
            <h1>MY DEAR </h1>
            <h1>LOVELY</h1>
            <h1 className={styles.name}>{name}</h1>
          </div>

          <div id="main" className={styles.main}>
            <div id="page1" className={styles.page1}>
              <nav className={styles.nav}>
                <img src={logo} className={styles.logoimg} alt="Logo" />
              </nav>
              <div id="center" className={styles.center}>
                <div id="left" className={styles.left}>
                  <h3 className={styles.capitalize}>{description1}</h3>
                  <div className="button-wrapper"></div>
                </div>
                <div id="right">
                  <h1 className={styles.name}>
                    I LOVE
                    <br />
                    YOU <br />
                  </h1>
                  <h1 className={styles.username}> {nickname}</h1>
                </div>
              </div>
              <div id="hero-shape"></div>

              <div className="animation-1">
                <div className="container">
                  {photos[0] && (
                    <img
                      src={photos[0]}
                      className={styles.saharsha}
                      alt="Photo 1"
                    />
                  )}
                </div>
                <div className="container pt-4">
                  <div className="row">
                    <div className={styles.col_sm_5}>
                      {photos[1] && (
                        <img
                          src={photos[1]}
                          className={styles.saharsha}
                          alt="Photo 2"
                        />
                      )}
                    </div>
                    <div className={styles.col_sm_5}>
                      {photos[2] && (
                        <img
                          src={photos[2]}
                          className={styles.saharsha}
                          alt="Photo 3"
                        />
                      )}
                    </div>{" "}
                  </div>
                </div>
              </div>
            </div>

            <div id="page2" className={styles.page2}>
              <div id="moving-text" className={styles.moving_text}>
                <div className={styles.con}>
                  <h1>LOVE</h1>
                  <div className={styles.gola}></div>
                  <h1>YOU</h1>
                  <div className={styles.gola}></div>
                  <h1>FOREVER</h1>
                  <div className={styles.gola}></div>
                  <h1>AND</h1>
                  <div className={styles.gola}></div>
                  <h1>ALWAYS</h1>
                  <div className={styles.gola}></div>
                </div>
                <div className={styles.con}>
                  <h1>LOVE</h1>
                  <div className={styles.gola}></div>
                  <h1>YOU</h1>
                  <div className={styles.gola}></div>
                  <h1>FOREVER</h1>
                  <div className={styles.gola}></div>
                  <h1>AND</h1>
                  <div className={styles.gola}></div>
                  <h1>ALWAYS</h1>
                  <div className={styles.gola}></div>
                </div>
                <div className={styles.con}>
                  <h1>LOVE</h1>
                  <div className={styles.gola}></div>
                  <h1>YOU</h1>
                  <div className={styles.gola}></div>
                  <h1>FOREVER</h1>
                  <div className={styles.gola}></div>
                  <h1>AND</h1>
                  <div className={styles.gola}></div>
                  <h1>ALWAYS</h1>
                  <div className={styles.gola}></div>
                </div>
              </div>

              <div className="overflow-hidden">
                <div className="container">
                  <div className="cake mb-4">
                    <img
                      src={cake}
                      className="img-fluid "
                      data-aos="slide-left"
                      data-aos-offset="300"
                      alt="Cake"
                    />
                  </div>{" "}
                  <div id="page2-bottom" className={styles.page2_bottom}>
                    {" "}
                    <img
                      src={ornaments}
                      className="img-fluid"
                      data-aos="slide-up"
                      data-aos-offset="300"
                      alt="Ornaments"
                    />
                    <img
                      src={chat}
                      className="img-fluid chat"
                      alt="Let's Celebrate"
                    />
                    <h1>{description2}</h1>
                  </div>
                </div>
                <div className={styles.gift}>
                  <img
                    src={letscelebrate}
                    className="img-fluid"
                    data-aos="slide-down"
                    data-aos-offset="150"
                    alt="Let's Celebrate"
                  />
                  <img
                    src={gifts}
                    className="img-fluid"
                    data-aos="slide-right"
                    data-aos-offset="150"
                    alt="Gifts"
                  />
                </div>
              </div>
            </div>

            <div id="page5" className={styles.page5}></div>
          </div>

          <div id="footer" className={styles.footer}>
            <div id="footer-div" className={styles.footer_div}></div>
            <h1>
              FROM <br />
              SNAPGREET
            </h1>
            <div id="footer-bottom" className={styles.footer_bottom}></div>
          </div>
        </>
      )}
    </div>
  );
};

export default Temp1;
